<script setup>
import { ref, inject, onMounted } from 'vue'
import Multiselect from '@vueform/multiselect'
import { sbapibackoffice } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import Dialog from '../../components/Dialog.vue'
// require('dayjs/locale/it')
// import dayjs from 'dayjs'
// import { spinnerStore } from '@/stores/spinner'
// dayjs.locale('it')
// import { navbarStore } from '@/stores/navbar'

import moment from 'moment'
import 'moment/locale/it'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const toast = inject('$toast')
const spinner = spinnerStore()
const navbar = navbarStore()
const deleteDialog = ref(null)

const visibilities = ref([])
const thisSectionVisible = ref(false)

onMounted(async () => {
  navbar.title = 'Box lanci'
  spinner.show()

  try {
    let results = await sbapibackoffice.get('home/banner')
    if (results?.data) {
      boxLanci.value = results.data

      // set datepicker value
      for (let i = 0; i < boxLanci.value.length; i++) {
        // init with moment "publicationStartDate" "publicationEndDate"
        boxLanci.value[i].date = [
          moment.utc(boxLanci.value[i].publicationStartDate),
          moment.utc(boxLanci.value[i].publicationEndDate)
        ]

        // set edited to false
        boxLanci.value[i].edited = false

        boxLanci.value[i].company.label = boxLanci.value[i].company.coid + ' - ' + boxLanci.value[i].company.name
      }

      globalSlideDuration.value = boxLanci.value[0].slideDurationSeconds
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  try {
    let results = await sbapibackoffice.get('home/sections-visibility')
    if (results?.data) {
      visibilities.value = results.data
      thisSectionVisible.value = results.data.find(v => v.sectionName == 'BANNER').visible
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  spinner.hide()
})

const boxLanci = ref([])

const noResults = ref(false)
const searchCompany = async (query) => {
  noResults.value = false

  try {
    if (query) {
      let results = await sbapibackoffice.get('companieslist?initialLettersOfCompanies=' + query)
      if (results?.data[0]?.coid) {
        // change all co_name to name
        results.data.forEach(company => {
          company.name = company.co_name
          company.label = company.coid + ' - ' + company.co_name
          delete company.co_name
        })
        
        return results.data
      }
      noResults.value = true
      return []
    }
    return []
  } catch (error) {
    toast.error('Errore durante la ricerca dell\'azienda: ' + error.message)
    noResults.value = true
    return []
  }
}

const dragStart = (event, id) => {
  // Store the dragged ID when drag starts
  draggedId.value = id
}

const dragOver = (event) => {
  event.preventDefault() // Prevent the default behavior to allow drop
}

const draggedId = ref(null)

const drop = async (event, dropId) => {
  event.preventDefault()

  const draggedBox = boxLanci.value.find(box => box.id === draggedId.value)
  const droppedBox = boxLanci.value.find(box => box.id === dropId)

  if (draggedBox && droppedBox) {
    // Swap the diplayOrder values of the dragged and dropped elements
    const tempOrder = draggedBox.diplayOrder
    draggedBox.diplayOrder = droppedBox.diplayOrder
    droppedBox.diplayOrder = tempOrder
  }

  try {
    const response = await sbapibackoffice.put('home/banner/update-display-order', [{
      'id': draggedBox.id,
      'displayOrder': draggedBox.diplayOrder
    },
    {
      'id': droppedBox.id,
      'displayOrder': droppedBox.diplayOrder
    }])

    if (response?.data) {
      toast.success('Box aggiornato con successo')
    }
  } catch (error) {
    toast.error('Errore durante la creazione del box: ' + error.message)
  }

  draggedId.value = null
}

const globalSlideDuration = ref()
const globalSlideDurationEdited = ref(false)

const newBox = () => {
  // if a new box is already present, don't add another one
  if (boxLanci.value.some((box) => !box.id)) {
    toast.warning('Completa il box corrente prima di crearne uno nuovo')
    return
  }
  boxLanci.value.push({
    company: {
      coid: 0,
      name: ''
    },
    link: '',
    linkMediaDesktop: '',
    linkMediaSmallDesktop: '',
    linkMediaMobile: '',
    seoText: '',
    slideDurationSeconds: globalSlideDuration,
    date: [new Date(), new Date()],
    edited: true,
    diplayOrder: 0
  })
}

const saveBox = async (box, avoidToast) => {
  // check if all required fields are filled
  if (!box.company.coid || !box.link || !box.linkMediaDesktop|| !box.linkMediaSmallDesktop || !box.linkMediaMobile || !box.seoText || !box.slideDurationSeconds || !box.date) {
    toast.error('Compila tutti i campi obbligatori')
    return
  }

  spinner.show()

  // change dates to UTC
  box.date[0] = moment(box.date[0]).utc()
  box.date[1] = moment(box.date[1]).utc()

  if (box.id) {
    // update existing box
    await updateBox(box, avoidToast)
  } else {
    // create new box
    await createBox(box, avoidToast)
  }

  spinner.hide()
}

const saveAllBoxes = async () => {
  spinner.show()

  for (let i = 0; i < boxLanci.value.length; i++) {
    boxLanci.value[i].slideDurationSeconds = globalSlideDuration
    await saveBox(boxLanci.value[i], true)
  }

  toast.success('Durata slide aggiornata con successo')

  spinner.hide()
}

// base64 to file
const base64ToBlob = (base64, type) => {
  const binStr = atob(base64.split(',')[1])
  const len = binStr.length
  const arr = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }

  return new Blob([arr], { type })
}

const createBox = async (box, avoidToast) => {
  // chack link url format
  if (!box.link.match(/^(http|https):\/\/[^ "]+$/)) {
    toast.error('Formato url errato. Utilizzare questo formato: https://ilmiosito.it')
    spinner.hide()
    return
  }
  
  // Creare un oggetto FormData
  const form = new FormData()

  // Aggiungere i campi del payload come stringa
  form.append('payload', JSON.stringify({
    'coid': box.company.coid,
    'link': box.link,
    'seoText': box.seoText,
    'slideDurationSeconds': box.slideDurationSeconds,
    'publicationStartDate': box.date[0].toISOString(),
    'publicationEndDate': box.date[1].toISOString()
  }))

  // Aggiungere i file, trasformando le immagini in file
  form.append('fileMediaDesktop', base64ToBlob(box.linkMediaDesktop, 'image/png'))
  form.append('fileMediaSmallDesktop', base64ToBlob(box.linkMediaSmallDesktop, 'image/png'))
  form.append('fileMediaMobile', base64ToBlob(box.linkMediaMobile, 'image/png'))

  try {
    const response = await sbapibackoffice.post('home/banner', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (response?.data) {
      box.id = response.data.id
      box.edited = false
      box.displayOrder = response.data.displayOrder
      toast.warning('avoidToast', avoidToast)
      if (!avoidToast)
        toast.success('Box creato con successo e aggiunto in fondo alla lista')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const updateBox = async (box, avoidToast) => {
  // chack link url format
  if (!box.link.match(/^(http|https):\/\/[^ "]+$/)) {
    toast.error('Formato url errato. Utilizzare questo formato: https://ilmiosito.it')
    spinner.hide()
    return
  }

  // Creare un oggetto FormData
  const form = new FormData()

  // Aggiungere i campi del payload come stringa
  form.append('payload', JSON.stringify({
    'link': box.link,
    'seoText': box.seoText,
    'slideDurationSeconds': box.slideDurationSeconds,
    'publicationStartDate': box.date[0].toISOString(),
    'publicationEndDate': box.date[1].toISOString()
  }))

  // Aggiungere i file, trasformando le immagini in file
  if (box.fileMediaDesktop_changed) {
    form.append('fileMediaDesktop', base64ToBlob(box.linkMediaDesktop, 'image/png'))
  }
  if (box.fileMediaSmallDesktop_changed) {
    form.append('fileMediaSmallDesktop', base64ToBlob(box.linkMediaSmallDesktop, 'image/png'))
  }
  if (box.fileMediaMobile_changed) {
    form.append('fileMediaMobile', base64ToBlob(box.linkMediaMobile, 'image/png'))
  }

  try {
    const response = await sbapibackoffice.put('home/banner/' + box.id, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (response?.data) {
      box.edited = false
      if (!avoidToast)
        toast.success('Box aggiornato con successo')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const boxToBeRemoved = ref(null)
const askRemovePermission = (box) => {
  deleteDialog.value.open()
  boxToBeRemoved.value = box
}

const removeBox = async () => {

  spinner.show()

  try {
    let results = await sbapibackoffice.delete('home/banner/' + boxToBeRemoved.value.id)
    if (results?.data) {
      boxLanci.value = boxLanci.value.filter((box) => box.id !== boxToBeRemoved.value.id)
      deleteDialog.value.close()
      toast.success('Box eliminato con successo')
    }
  } catch (error) {
    toast.error('Errore durante l\'eliminazione del box: ' + error.message)
  }

  spinner.hide()
}

const removeDesktopImage = (box) => {
  box.linkMediaDesktop = ''
  box.edited = true
  box.fileMediaDesktop_changed = true
}
const removeSmallDesktopImage = (box) => {
  box.linkMediaSmallDesktop = ''
  box.edited = true
  box.fileMediaSmallDesktop_changed = true
}
const removeMobileImage = (box) => {
  box.linkMediaMobile = ''
  box.edited = true
  box.fileMediaMobile_changed = true
}

const boxToUpdate = ref(null)

const startUploadDesktop = (box) => {
  boxToUpdate.value = box
  document.getElementById('fileInputDesktop').click()
}

const startUploadSmallDesktop = (box) => {
  boxToUpdate.value = box
  document.getElementById('fileInputSmallDesktop').click()
}

const startUploadMobile = (box) => {
  boxToUpdate.value = box
  document.getElementById('fileInputMobile').click()
}

const uploadDesktopImage = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    boxToUpdate.value.linkMediaDesktop = e.target.result
    boxToUpdate.value.edited = true
    boxToUpdate.value.fileMediaDesktop_changed = true
  }

  reader.readAsDataURL(file)

  // reset the input value
  event.target.value = ''
}

const uploadSmallDesktopImage = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    boxToUpdate.value.linkMediaSmallDesktop = e.target.result
    boxToUpdate.value.edited = true
    boxToUpdate.value.fileMediaSmallDesktop_changed = true
  }

  reader.readAsDataURL(file)

  // reset the input value
  event.target.value = ''
}

const uploadMobileImage = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    boxToUpdate.value.linkMediaMobile = e.target.result
    boxToUpdate.value.edited = true
    boxToUpdate.value.fileMediaMobile_changed = true
  }

  reader.readAsDataURL(file)

  // reset the input value
  event.target.value = ''
}

const toggleVisibility = async (event) => {
  // if i want to toggle on i need to check if there is at leat one box
  if (event.target.checked && boxLanci.value.length < 1) {
    toast.warning('Per attivare i Banner, è necessario caricare almeno 1 elemento')
    thisSectionVisible.value = false
    return
  }

  try {
    let sectionId = visibilities.value.find(v => v.sectionName == 'BANNER').id
    await sbapibackoffice.put(`home/sections-visibility/${sectionId}`, { visible: event.target.checked })

    // hide LAUNCHBOX
    if (event.target.checked) {
      sectionId = visibilities.value.find(v => v.sectionName == 'LAUNCH_BOX').id
      await sbapibackoffice.put(`home/sections-visibility/${sectionId}`, { visible: false })
    }

    if (event.target.checked) {
      toast.success('Sezione visibile su cving.com, sezione "Box lanci" nascosta')
    } else {
      // if LAUNCH_BOX is also hidden, show the message
      if (!visibilities.value.find(v => v.sectionName == 'LAUNCH_BOX').visible) {
        toast.warning('Sezione nascosta. NB: In home sono disattivati sia BANNER che BOX LANCI.')
      } else {
        toast.success('Sezione nascosta su cving.com')
      }
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }
}

const onCompanySelected = (box, company) => {
  if (company) {
    box.company = company
    box.edited = true
  }
}
</script>

<template>
  <div class="container">
    <div class="cms_description">
      <p>Sezione per inserire, modificare o eliminare i banner rettangolari presenti in Homepage.</p>
      <p>Se attiva, viene automaticamente disattivata la sezione "Box lanci" e viceversa.</p>
      <p>I campi<span class="required_symbol">*</span> sono obbligatori</p>
      <!-- switch toggle to hide show the entire cms section -->

      <div class="actions">
        <div class="left-actions">
          <div class="switch-toggle">
            <input id="switch" v-model="thisSectionVisible" type="checkbox" @change="toggleVisibility">
            <label for="switch">Visibile su cving.com</label>
          </div>
          
          <div class="global-slide-duration">
            <label for="globalSlideDuration">Durata slide (secondi)</label>
            <input id="globalSlideDuration" v-model="globalSlideDuration" type="number" min="1" @input="globalSlideDurationEdited = true">
            <font-awesome-icon v-if="globalSlideDurationEdited" class="saveItem" icon="floppy-disk" @click="saveAllBoxes(box)" />
          </div>
        </div>

        <div>
          <material-button text="Crea nuovo box" type="success" @click="newBox" />
        </div>
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th><!-- drag icon --></th>
            <th>Azienda<span class="required_symbol">*</span></th>
            <th style="text-align: center;min-width: 235px;">
              <p>Link<span class="required_symbol">*</span></p>
              <p>(Formato: https://ilmiosito.it)</p>
            </th>
            <th>Desktop Grande (1800 x 510)<span class="required_symbol">*</span></th>
            <th>Desktop Piccolo (672 x 324)<span class="required_symbol">*</span></th>
            <th>Mobile (327 x 300)<span class="required_symbol">*</span></th>
            <th>SEO<span class="required_symbol">*</span></th>
            <th class="date_column">
              Data<span class="required_symbol">*</span>
            </th>
            <th><!-- trash icon --></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="box in boxLanci.sort((a, b) => a.diplayOrder - b.diplayOrder)"
            :key="box.id"
            draggable="true"
            @dragstart="dragStart($event, box.id)"
            @dragover="dragOver($event)"
            @drop="drop($event, box.id)"
          >
            <td>
              <font-awesome-icon
                class="dragItem"
                icon="grip-vertical"
                style="pointer-events: none;"
              />
            </td>
            <td>
              <div class="company_column">
                <Multiselect
                  :key="box.company ? 'azienda_coid_' + box.company.coid : 'no_selection'"
                  v-model="box.company"
                  placeholder="Cerca l'azienda"
                  :object="true"
                  :filter-results="false"
                  :min-chars="1"
                  :resolve-on-load="false"
                  :delay="250"
                  :searchable="true"
                  label="label"
                  track-by="coid"
                  value-prop="coid"
                  :clear-on-select="true"
                  :no-options-text="noResults ? 'Nessuna azienda corrisponde alla ricerca effettuata' : 'Inizia a digitare...'"
                  :options="async (query) => await searchCompany(query)"
                  @select="onCompanySelected(box, $event)"
                />
              </div>
            </td>
            <td>
              <div class="text_column">
                <input v-model="box.link" type="text" @change="box.edited = true">
              </div>
            </td>
            <td class="file_column">
              <div v-if="box.linkMediaDesktop" class="image-wrapper">
                <font-awesome-icon
                  class="delete-item-image"
                  icon="trash-can"
                  @click="removeDesktopImage(box)"
                />
                <img :src="box.linkMediaDesktop" alt="desktop">
              </div>
              <button v-else class="add-file cursor-pointer" @click="startUploadDesktop(box)" v-text="'Carica file'" />
            </td>
            <td class="file_column">
              <div v-if="box.linkMediaSmallDesktop" class="image-wrapper">
                <font-awesome-icon
                  class="delete-item-image"
                  icon="trash-can"
                  @click="removeSmallDesktopImage(box)"
                />
                <img :src="box.linkMediaSmallDesktop" alt="smalldesktop">
              </div>
              <button v-else class="add-file cursor-pointer" @click="startUploadSmallDesktop(box)" v-text="'Carica file'" />
            </td>
            <td class="file_column">
              <div v-if="box.linkMediaMobile" class="image-wrapper">
                <font-awesome-icon
                  class="delete-item-image"
                  icon="trash-can"
                  @click="removeMobileImage(box)"
                />
                <img :src="box.linkMediaMobile" alt="mobile">
              </div>
              <button v-else class="add-file cursor-pointer" @click="startUploadMobile(box)" v-text="'Carica file'" />
            </td>
            <td>
              <div class="text_column">
                <input v-model="box.seoText" type="text" @change="box.edited = true">
              </div>
            </td>
            <td class="date_column">
              <Datepicker
                :model-value="box.date"
                range
                multi-calendars
                auto-apply
                :enable-time-picker="true"
                :clearable="false"
                format="dd/MM/yyyy HH:mm"
                @update:model-value="box.edited = true; box.date = $event"
              />
            </td>
            <td>
              <font-awesome-icon v-if="box.edited" class="saveItem" icon="floppy-disk" @click="saveBox(box)" />
              <font-awesome-icon
                v-else
                class="deleteItem"
                icon="trash-can"
                @click="askRemovePermission(box)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <input id="fileInputDesktop" type="file" style="display: none" accept="image/*" @change="uploadDesktopImage">
  <input id="fileInputSmallDesktop" type="file" style="display: none" accept="image/*" @change="uploadSmallDesktopImage">
  <input id="fileInputMobile" type="file" style="display: none" accept="image/*" @change="uploadMobileImage">

  <Dialog ref="deleteDialog" title="Vuoi Eliminare?" subtitle="se confermi, il box verrà eliminato" button-text="Conferma" @buttonAction="removeBox" />
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.container { padding: 20px; }

.cms_description {
  color: white;
  font-size: 1rem;
  
  p {
    margin-bottom: 10px;

    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    
    .left-actions {
      display: flex;
      gap: 40px;

      .switch-toggle {
        margin-top: 15px;
        margin-bottom: 30px;
        
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .global-slide-duration {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 13px;

        input {
          width: 50px;
          height: 30px;
          padding: 5px;
          border-radius: 4px;
          outline: none;
          font-size: 14px;
          background: transparent;
          font-size: 14px;
          color: white;
          border: 1px solid #d1d5db;
          text-align: center;
        }
      }
    }
  }
}

// .required_symbol {
//   color: red;
//   font-size: 1.5rem;
// }

.table {
  color: white;
  height: calc(100vh - 225px);
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #1a74a3;
      position: sticky;
      top: 0;
      z-index: 100;

      th {
        padding: 10px;
        text-align: left;
        
        &:not(:first-child):not(:last-child) {
          min-width: 200px;

          &.number_column {
            width: auto;
            min-width: 82px;
            max-width: 82px;
          }

          &.date_column {
            min-width: 260px;
          }
        }
      }
    }
    tbody {
      user-select: none;
      border-radius: 14px;
      tr {
        border-right: 1px solid #f4f4f438;
        border-left: 1px solid #f4f4f438;
        
        td {
          padding: 20px 10px;
          border-bottom: 1px solid #f4f4f438;
          border-radius: 14px;
          
          input {
            color: white;
          }

          .deleteItem {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(253, 102, 102);
          }

          .dragItem {
            cursor: grab;
            height: auto;
            min-width: 12px;
          }

          .company_column {
            min-width: 200px;
          }

          .text_column {
            width: auto;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.file_column {
            width: auto;
            padding: 0 10px;

            .image-wrapper {
              position:relative;
              width: fit-content;

              img {
                max-width: 300px;
              }
            }

            .add-file {
              max-width: 130px;
              min-width: unset !important;
              overflow: visible !important;
              white-space: normal;
              line-height: unset !important;
              font-size: 10.5px;
              background: var(--info);
              color: white;
              text-transform: uppercase;
              outline: 0;
              box-shadow: none;
              border-radius: 4px;
              border: 2px solid var(--info);
              height: 41px;
              padding: 0 10px;
            }

            .delete-item-image {
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;
              width: 16px;
              height: auto;
              color: rgb(253, 102, 102);
            }
          }

          &.number_column {
            width: auto;
            max-width: 82px;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.date_column {
            width: 260px;
          }
        }
      }
    }
  }
}

.saveItem {
  cursor: pointer;
  width: 16px;
  height: auto;
  color: rgb(102, 253, 102);
}

// hide number input arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
.date_column input {
  background-color: transparent;
  color: white;
  height: 41px;
}
</style>